import React from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import MySwiper from '../components/Swiper'
// import WebriQForm from '@webriq/gatsby-webriq-form'
// import { Tween, Timeline } from 'react-gsap';
import Modal from '../components/Modal'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './index.css'

class IndexPage extends React.Component {
  render() {
    const siteTitle = 'Cost effective, superfast and secure websites'
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <section id="different-agency" className="component">
          <Container>
            <Row>
              <Col md={`${4} mb-4`}>
                <div className="agency-list text-center">
                  <div className="agency-img mb-4">
                    <a
                      className="hvr-ripple-out ripple-orange"
                      href="https://webriqgoesmad.webriq.com/services"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        effect="blur"
                        src="/img/icon-client.png"
                        className="img-fluid mb-0"
                        alt="Entrepreneur"
                      />
                    </a>
                  </div>
                  <h5 className="font-weight-normal mt-2">
                    <a
                      className="text-blue-13"
                      href="https://webriqgoesmad.webriq.com/services"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Entrepreneur
                    </a>
                  </h5>
                </div>
              </Col>
              <Col md={`${4} mb-4`}>
                <div className="agency-list text-center">
                  <div className="agency-img mb-4">
                    <a
                      className="hvr-ripple-out ripple-teal"
                      href="https://webriqgoesmad.webriq.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        effect="blur"
                        src="/img/icon-business.png"
                        className="img-fluid mb-0"
                        alt="Business"
                      />
                    </a>
                  </div>
                  <h5 className="font-weight-normal mt-2">
                    <a
                      className="text-blue-13"
                      href="https://webriqgoesmad.webriq.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Business
                    </a>
                  </h5>
                </div>
              </Col>
              <Col md={`${4} mb-4`}>
                <div className="agency-list text-center">
                  <div className="agency-img mb-4">
                    <a
                      className="hvr-ripple-out ripple-blue"
                      href="https://glue.webriq.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        effect="blur"
                        src="/img/icon-corporate.png"
                        className="img-fluid mb-0"
                        alt="Corporate"
                      />
                    </a>
                  </div>
                  <h5 className="font-weight-normal mt-2">
                    <a
                      className="text-blue-13"
                      href="https://glue.webriq.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Corporate
                    </a>
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="what-we-offer" className="component">
          <Container>
            <Row className="align-items-center row justify-content-center">
              <Col md={6} className="ml-lg-auto">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-1.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Blazing Performance
                      </h5>
                      <p>
                        the service guarantees you insanely fast loading times,
                        even on slow mobile networks.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/icon-performance.svg"
                    alt="performance"
                    width="254"
                    height="361"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="cost" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col
                md={5}
                className="text-center mb-4 mb-lg-0 order-2 order-md-1"
              >
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/icon-cost.svg"
                  alt="cost"
                  width="224"
                  height="312"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-2.png"
                        alt="2"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Monthly recurring cost
                      </h5>
                      <p>
                        No upfront costs, and a monthly recurring costs in line
                        with the functionality of your website.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="up-to-date" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-3.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Always up to date
                      </h5>
                      <p>
                        with unlimited updates to content and design, your
                        website always reflects the current state of your
                        business
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/icon-content.svg"
                    alt="content"
                    width="206"
                    height="336"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="security" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col
                md={5}
                className="text-center mb-4 mb-lg-0 order-2 order-md-1"
              >
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/icon-security.svg"
                  alt="security"
                  width="284"
                  height="264"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-4.png"
                        alt="4"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Security</h5>
                      <p>
                        Having no active database, your website is immune to
                        malicious injections and hacks
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="scalable" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-5.png"
                        alt="5"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Scalable</h5>
                      <p>
                        whether you have 100 or 10,000 visitors a month, your
                        website download speeds remain the same, and your
                        recurring monthly bill remains the same
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/icon-scalable.svg"
                    alt="content"
                    width="263"
                    height="313"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="cms" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center order-2 order-md-1">
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/icon-cms.svg"
                  alt="cms"
                  width="244"
                  height="308"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-6.png"
                        alt="6"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Web Portal for your project
                      </h5>
                      <p>
                        with integrated Google and WebriQ Analytics, WebriQ
                        Forms and Content Management System (CMS)
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="developers" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-7.png"
                        alt="7"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Pool of designers and developers using the most modern
                        web techniques
                      </h5>
                      <p>
                        a technology stack for building modern websites and web
                        applications
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/icon-developers.svg"
                    alt="content"
                    width="310"
                    height="242"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="helpdesk" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col
                md={5}
                className="text-center mb-4 mb-lg-0 order-2 order-md-1"
              >
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/icon-helpdesk.svg"
                  alt="cms"
                  width="244"
                  height="308"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/offer-8.png"
                        alt="8"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Helpdesks support
                      </h5>
                      <p>email, chat, text and speak to humans</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="webriq-buttons" className="component">
          <Container>
            <div className="d-flex justify-content-center component pt-0 mb-3">
              <Modal
                btnclass="hvr-shadow btn btn-primary text-left"
                html=<div className="d-flex justify-content-start align-items-center">
                  <LazyLoadImage
                    className="img-fluid mb-0 mr-4"
                    effect="blur"
                    src="/img/icon-phone.png"
                    alt="schedule a consult"
                  />
                  <span>Schedule A Consult</span>
                </div>
              >
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/alex-belding/lets-build-a-website?month=2019-08"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Modal>
            </div>
            {/*<Row>
              <Col md={`${9} mx-auto`}>
                <div id="webriq-cta" className="text-center">
                  <Row className="justify-content-center flex-column flex-lg-row">
                    <Col xs={`${11} mb-3`} sm={`${8} mx-auto mb-sm-0`} lg={`${5} mx-lg-0`}>
                      <a className="hvr-shadow mb-0 d-block btn btn-primary btn-orange" href="https://webriqgoesmad.webriq.com" target="_blank" rel="noreferrer noopener">Business Solutions</a>
                    </Col>
                    <Col xs={11} sm={`${8} mx-auto`} lg={`${5} mx-lg-0`}>
                      <a className="hvr-shadow mb-4 mb-lg-0 d-block  btn btn-primary btn-inverted" href="https://glue.webriq.com" target="_blank" rel="noreferrer noopener">Enterprise Solutions</a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>*/}
          </Container>
        </section>
        <section id="latest-blog" className="component">
          <Container>
            <Row>
              <div className="col-12 text-center">
                <h3 className="my-5">Latest Blog</h3>
              </div>
              <div className="col article-grid">
                {posts.map(({ node }) => {
                  return (
                    <div className={`article main`} key={node.id}>
                      <div
                        className="article-cover-image lazy"
                        style={{
                          backgroundImage: `url(${node.mainImage &&
                            node.mainImage.asset &&
                            node.mainImage.asset &&
                            node.mainImage.asset.fluid &&
                            node.mainImage.asset.fluid.src})`,
                        }}
                      />
                      <Link
                        to={node.slug.current}
                        className="article-action-trigger"
                        title={node.title}
                      />
                      <div
                        className="article-cover-gradient"
                        style={{
                          backgroundImage: `url(/img/image-gradient.png)`,
                        }}
                      />
                      <div className="article-content">
                        <h3 className="article-title">
                          <Link
                            to={node.slug.current}
                            className="text-white"
                            title={node.title}
                          >
                            {node.title}
                          </Link>
                        </h3>
                        <div className="article-info">
                          <ul className="m-0 list-unstyled d-flex flex-wrap">
                            {node.authors.length !== 0 && (
                              <li className="mr-3 mb-0 mb-sm-3">
                                <i className="far fa-user-circle mr-2" />
                                <Link
                                  className="text-white"
                                  to={`/author/${node.authors[0].person.slug.current}`}
                                >
                                  {node.authors[0].person.name}
                                </Link>
                              </li>
                            )}
                            <li>
                              <i className="far fa-clock mr-2" />
                              {node.publishedAt
                                ? node.publishedAt
                                : node._createdAt}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          body
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
